import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import Close from "../../assets/icons/Close.svg";
import Upload from "../../assets/icons/Upload.svg";
import useModal from "./../hooks/ModalHook";
import { closeModal } from "../../redux/slices/modalSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setEventField } from "../../redux/slices/chatSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MyTimePicker from "./widgets/TimePicker";
import Event from "../../assets/icons/calendar.svg";
import { postRequestUnAuthenticated } from "./../../services/rest";

const EventModal = () => {
  const { handleOpenModal } = useModal();
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat);
  const [suggestions, setSuggestions] = useState([]);

  const [event, setEvent] = useState({
    name: "",
    description: "",
    startDate: "",
    startTime: "",
    endTime: "",
    locationText: "",
    location: "",
  });

  const handleClose = () => {
    // dispatch(clearEvent());

    dispatch(closeModal("modalEventOpen"));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
    if (name === "name") {
      setCharCount(value.length);
    } else if (name === "description") {
      setDescCount(value.length);
    }
  };

  const handleDateChange = (date) => {
    const isoDate = date ? date.toISOString() : "";
    setEvent((prevEvent) => ({
      ...prevEvent,
      startDate: isoDate,
    }));
  };

  const handleStartTimeChange = (date) => {
    const isoString = date
      ? date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
      : "";
    setEvent((prevEvent) => ({
      ...prevEvent,
      startTime: isoString,
    }));
  };

  const handleEndTimeChange = (date) => {
    const isoString = date
      ? date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
      : "";
    setEvent((prevEvent) => ({
      ...prevEvent,
      endTime: isoString,
    }));
  };

  const fetchSuggestions = async (input) => {
    if (input.trim() === "") {
      setSuggestions([]);
      return;
    }

    try {
      const response = await postRequestUnAuthenticated(
        `/places/autocomplete?input=${encodeURIComponent(input)}`
      );
      const data = response;

      if (data.status === "OK" && data.predictions) {
        const descriptions = data.predictions.map((prediction) => {
          const description = prediction.description;
          const placeId = prediction.place_id;
          const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${description}&query_place_id=${placeId}`;
          return { description, mapsUrl };
        });

        setSuggestions(descriptions);
      } else {
        console.log("No predictions found or status not OK");
      }
    } catch (error) {
      console.log("Error fetching suggestions:", error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    fetchSuggestions(value);
    setEvent((prevEvent) => ({
      ...prevEvent,
      locationText: value,
    }));
  };

  const handleSuggestionClick = (suggestion) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      location: suggestion,
    }));
    setSuggestions([]);
  };

  const handleCreateCuration = async (e) => {
    e.preventDefault();

    // if (curation.name.trim !== "") {
    //   const formDataToSend = new FormData();
    //   formDataToSend.append("name", curation.name.trim());
    //   formDataToSend.append("visibility", curation.visibility);
    //   formDataToSend.append("description", curation.description);
    //   formDataToSend.append("category", curation.category);
    //   formDataToSend.append("profile_category", curation.profile_category);
    //   if (file && curation.imageSource === "upload") {
    //     formDataToSend.append("file", file);
    //   } else if (curation.image && curation.imageSource === "unsplash") {
    //     formDataToSend.append("image", curation.image);
    //   } else {
    //     const encodedCategory = curation.category
    //       ? curation.category
    //       : "NoCategory";
    //     const imageUrl = curationImages[encodedCategory];
    //     formDataToSend.append("image", imageUrl);
    //   }
    //   dispatch(createCuration(formDataToSend))
    //     .unwrap()
    //     .then(() => {
    //       handleClose();
    //       dispatch(clearCuration());
    //       setFile(null);
    //       setError("");
    //     })
    //     .catch((error) => {
    //       alert(error);
    //     });
    // }
  };

  const [charCount, setCharCount] = useState(0);
  const [descCount, setDescCount] = useState(0);
  const maxChars = 50;
  const maxDesc = 250;
  const isNameEmpty = event.name.trim() === "";

  const buttonClass = isNameEmpty
    ? "dark:text-buttonDisable-dark dark:text-opacity-40 dark:bg-buttonDisable-dark dark:bg-opacity-10"
    : "dark:bg-secondaryText-dark dark:text-primaryBackground-dark";
  const isOpen = useSelector((state) => state.modals.modalEventOpen);

  const ReadOnlyDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      readOnly
      value={value}
      onClick={onClick}
      ref={ref}
      placeholder="Date"
      className="w-[210%] py-1 text-sm pr-10 font-light rounded dark:bg-transparent
       border-b border-b-chatDivider-dark placeholder-font-light placeholder-text-sm 
       dark:text-secondaryText-dark focus:outline-none placeholder:text-emptyEvent-dark"
    />
  ));

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 z-50" />
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Dialog.Content className="dark:bg-secondaryBackground-dark rounded-xl overflow-hidden shadow-xl transform transition-all min-h-[20%] max-h-[80%] overflow-y-auto custom-scrollbar w-[90%] xs:w-3/4 sm:w-1/2 md:w-2/5 lg:w-[35%] xl:w-[30%]">
            <Dialog.Title />
            <div className="flex flex-col p-5">
              <div className="flex justify-between items-center mb-4">
                <h2 className="dark:text-secondaryText-dark text-lg font-normal fonr-inter">
                  New Event
                </h2>
                <img
                  src={Close}
                  alt="Close"
                  className="w-4 h-4 cursor-pointer"
                  onClick={handleClose}
                />
              </div>
              <div className="mb-4">
                <input
                  id="curation-name"
                  className="w-full  p-0.5 rounded bg-chipBackground 
                   placeholder:font-light placeholder:text-xl text-xl font-light
                   focus:outline-none dark:bg-transparent border-b dark:border-b-chatDivider-dark  dark:text-secondaryText-dark  placeholder:dark:text-emptyEvent-dark"
                  type="text"
                  name="name"
                  value={event.name}
                  autocomplete="off"
                  onChange={handleChange}
                  maxLength={maxChars}
                  placeholder="Event Name"
                />
              </div>

              <div className="relative mb-2">
                <textarea
                  value={event.description}
                  onChange={handleChange}
                  name="description"
                  maxLength={maxDesc}
                  className="w-full text-sm pt-2 font-inter pb-3 pl-3 pr-3 rounded-lg border
                   font-light dark:border-chatDivider-dark dark:bg-transparent dark:text-secondaryText-dark focus:border-primary
                    focus:ring-0 focus:outline-none placeholder:dark:text-emptyEvent-dark"
                  rows="2"
                  placeholder="Add Description"
                />
                <div className="dark:text-primaryText-dark absolute right-2 bottom-3 text-xs text-textfieldBorder">
                  {descCount}/{maxDesc}
                </div>
              </div>

              <div className="relative">
                <input
                  className="w-full py-1 mr-3 text-sm mt-1 pr-3 font-light rounded no-scrollbar dark:bg-transparent
                        border-b dark:border-b-chatDivider-dark placeholder-font-light placeholder-text-sm dark:text-secondaryText-dark
                        focus:outline-none placeholder:dark:text-emptyEvent-dark resize-none"
                  placeholder="Enter location"
                  name="location"
                  onChange={handleInputChange}
                  value={event.location}
                  autoComplete="off"
                />
                {suggestions.length > 0 && (
                  <img
                    src={Close}
                    alt="clear"
                    className="absolute right-1 top-4 w-3 h-3 cursor-pointer"
                    onClick={() => setSuggestions([])}
                  />
                )}
                {suggestions.length > 0 && (
                  <div className="absolute top-10  dark:bg-tertiaryBackground-dark text-white text-xs pr-1 mr-2 rounded-lg  w-[90%]">
                    <ul>
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className="px-4 py-2  cursor-pointer"
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          <i className="text-white mr-2 fas fa-map-marker-alt"></i>
                          {suggestion.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="relative mt-3 w-full">
                <DatePicker
                  selected={event.startDate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy"
                  customInput={<ReadOnlyDateInput />}
                />
                <img src={Event} alt="event" className-="w-5 h-5 mr-1" />
              </div>

              <MyTimePicker
                chipData={event}
                handleStartTimeChange={handleStartTimeChange}
                handleEndTimeChange={handleEndTimeChange}
              />

              <button
                className={`w-full mt-3 py-2.5 font-normal text-sm rounded-lg ${buttonClass}`}
                disabled={isNameEmpty}
              >
                Create Event
              </button>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default EventModal;
